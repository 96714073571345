<template>
  <div style="width: 100%;">
    <v-app>
      <v-snackbar
        :timeout="3000"
        :value="true"
        absolute
        top
        color="success"
        right
        text
        v-if="appear"
      >
        Level created
      </v-snackbar>
      <modalTest :test="show" @closeModal="closeModal($event)" @getResponse="responseData($event)"/>
      <v-dialog
        v-model="dialogModal"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Department</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select
                    @click="fetchSelectedDepartments"
                    v-model="dept"
                    label="Choose faculty"
                    name="Faculty"
                    v-validate="'required'"
                    :items="departments"
                  ></v-select>
                  <span style="color: red;"
                    v-show="errors.has('Faculty')">{{ errors.first('Faculty') }}</span>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="close"
              style="text-transform: none"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="edit('department_id')"
              style="text-transform: none"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :options.sync="options"
        :server-items-length="totalDesserts"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:item.id="{item}">
          <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
            {{desserts.map(function(x) {return x.id; }).indexOf(item.id)+1}}
          </v-chip>
        </template>
        <template v-slot:item.title="{ item }">
          <input type="text" style="width: auto;" :value="item.title" :id="'title'+item.id" @dblclick="editMode(item.id,'title')" @blur="blurMode(item.id,'title')" @keyup.enter="blurMode(item.id,'title')" readonly/>
        </template>
        <template v-slot:item.department="{ item }">
          <input type="text" style="width: 100%;" :value="item.department" :id="'department'+item.id" @dblclick="onModal(item.id)" readonly/>
        </template>
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Levels</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              style="text-transform:none;"
              @click="modalbtn"
            >
              Add Level
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mt-2"
            @click="edit(item),state='edit'"
          >
          </v-icon>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>
<script>
import {
  mdiAccount,
  mdiPencil,
  mdiShareVariant,
  mdiDelete,
} from '@mdi/js'
import modalTest from './newLevel'

export default {
  components: {
    modalTest,
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiPencil,
      mdiShareVariant,
      mdiDelete,
    },
    headers: [
      { text: '#', value: 'id' },
      { text: 'Title', value: 'title' },
      { text: 'Department', value: 'department' },
      { text: '', value: 'actions' },
    ],
    date: new Date().toISOString().substr(0, 7),
    modal: false,
    dialogModal: false,
    logger: [],
    header: '',
    editedItem: {
      title: '',
    },
    state: '',
    id: '',
    levels: [],
    departments: [],
    show: false,
    levelId: '',
    dept: null,
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    appear: '',
  }),
  watch: {
    options: {
      handler () {
        this.fakeApiCall()
      },
      deep: true,
    },
  },
  created () {
    this.fetchLevels()
  },
  methods: {
    editMode: function (id, key) {
      var level = document.getElementById(key + '' + id)
      level.focus()
      level.readOnly = false
      level.classList.add('editMode')
    },
    blurMode (id, key) {
      var level = document.getElementById(key + '' + id)
      level.readOnly = true
      level.classList.remove('editMode')
      this.editLevel(id, key, level.value)
    },
    async editLevel (id, key, value) {
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: id,
        key: key,
        value: value,
      }
      try {
        await this.axios({
          url: 'manipulate_levels/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(e)
        }
      }
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save()
        } else {
          // form have errors
        }
      })
    },
    modalbtn () {
      this.show = true
    },
    closeModal (event) {
      this.show = event
    },
    close () {
      this.dialogModal = false
      this.state = ''
      this.editedItem.title = ''
      this.editedItem.selected = ''
    },
    async fetchSelectedDepartments () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_departments',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.departments = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    async edit (key) {
      const { page, itemsPerPage } = this.options
      if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      const data = {
        id: this.levelId,
        key: key,
        value: this.dept,
      }
      try {
        const response = await this.axios({
          url: 'manipulate_levels/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (response.status === 200) {
          this.dialogModal = false
          this.fetchLevels((page - 1) * itemsPerPage, page * itemsPerPage)
          this.selected = ''
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else if (e.response && e.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(e)
        }
      }
    },
    responseData (event) {
      this.appear = true
      console.log(event)
      this.levels.push(event)
      this.show = false
    },
    onModal (id) {
      this.dialogModal = true
      this.levelId = id
    },
    fetchLevels (start, end) {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      this.axios({
        url: 'get_levels/' + start + '/' + end,
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + this.header,
        },
      })
        .then((response) => {
          // Then injecting the result to datatable parameters.
          this.loading = false
          this.desserts = response.data.levels
          this.totalDesserts = parseInt(response.data.total)
          return response.data.levels
        })
    },
    fakeApiCall () {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let items = this.fetchLevels((page - 1) * itemsPerPage, itemsPerPage)
        const total = this.desserts.length
        if (sortBy.length === true && sortDesc.length === true) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]]
            const sortB = b[sortBy[0]]
            if (sortDesc[0]) {
              if (sortA < sortB) return 1
              if (sortA > sortB) return -1
              return 0
            } else {
              if (sortA < sortB) return -1
              if (sortA > sortB) return 1
              return 0
            }
          })
        }
        if (itemsPerPage > 0) {
          this.desserts = this.desserts.slice((page - 1) * itemsPerPage, page * itemsPerPage)
        }
        setTimeout(() => {
          resolve({
            items,
            total,
          })
        }, 1000)
      })
    },
  },
}
</script>
<style scoped>
  .editMode {
    padding: 3px;
    background-color: #fdf9ff;
    font-size: 14px;
    border: 1px solid;
    border-radius: 3px;
  }
</style>
