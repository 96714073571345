<template>
  <v-dialog
    v-model="test"
    max-width="500px"
    :persistent="true"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Record new Level</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="editedItem.title"
                label="Title"
                name="Title"
                v-validate="'required'"
              ></v-text-field>
              <span style="color: red;"
                v-show="errors.has('Title')" v-if="valid">{{ errors.first('Title') }}</span>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                v-model="editedItem.selected"
                label="select department"
                name="Department"
                v-validate="'required'"
                :items="departments"
                @click="fetchSelectedDepartments"
              ></v-select>
              <span style="color: red;"
                v-show="errors.has('Department')" v-if="valid">{{ errors.first('Department') }}</span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="swicthModal"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="validate"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'modalTest',
  props: {
    test: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      logger: [],
      header: '',
      editedItem: {
        title: '',
        selected: null,
      },
      departments: [],
      state: '',
      id: '',
      valid: true,
    }
  },
  methods: {
    swicthModal: function () {
      this.editedItem.title = ''
      this.editedItem.selected = ''
      this.valid = false
      this.$emit('closeModal', false)
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.validate()
        } else {
          // form have errors
        }
      })
    },
    async saveData () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      if (this.editedItem.title === '' || this.editedItem.selected === '') {
        this.validate()
      } else {
        const data = {
          title: this.editedItem.title,
          deptId: this.editedItem.selected,
          id: this.id,
        }
        try {
          const res = await this.axios({
            url: 'manipulate_levels',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.$emit('getResponse', res.data)
            this.editedItem.title = ''
            this.editedItem.selected = ''
            this.valid = false
            this.swicthModal()
            this.fetchLevels()
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            localStorage.removeItem('logged_user')
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 400) {
            alert(e.response.data.messages)
            this.title = ''
            this.code = ''
            this.selected = ''
            this.valid = false
          } else {
            console.log(e)
          }
        }
      }
    },
    async fetchSelectedDepartments () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_departments',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.departments = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('logged_user')
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
